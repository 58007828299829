footer {
  background: url("../../assets/images/Vector\ \(2\).svg"), $color-gallery;
  background-position: center;
  background-size: cover;
  //   gap: 4rem;
  height: 80vh;
  color: $color-light-white;
  position: relative;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: $width-tablet) {
    background: $color-primary !important;
    grid-template-columns: repeat(2, 1fr);

    .footer__items-3 {
      grid-row: 2;
      grid-column: 1 / 3;
    }

    .footer__items-2 {
      border: none !important;
    }

    @media (max-width: $width-mobile-xl) {
      height: auto;
      grid-template-columns: repeat(1, 1fr);

      .footer__items {
        border: none !important;

        .extra__info {
          width: 100% !important;
        }
      }

      .footer__items-3 {
        grid-row: auto;
        grid-column: 1 / -1;
      }
    }
  }

  .footer__items-3 {
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    .map {
      width: 80%;
      height: 65%;
      display: flex;

      @media (max-width: $width-1000p) {
        width: 80%;
        height: 50%;

        @media (max-width: $width-tablet) {
          height: 90%;
          width: 100%;
          flex: 1;
        }
      }
      // height:
      iframe,
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .map iframe {
    display: block !important;
  }

  // margin-top: 2rem;

  .footer {
    &__info {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      span {
        font-size: 1.4rem;
      }

      &-item {
        display: flex;
        gap: 0.8rem;
        text-transform: capitalize;
        // justify-content: center;

        .img {
          width: 2.4rem;

          svg,
          img {
            width: 100%;
          }
        }
      }
    }

    &__logo {
      width: 20rem;
      // margin: 0 auto;
      margin-bottom: 2.4rem;
      margin-left: 2rem;
      img {
        width: 100%;
      }
    }

    &__title {
      font-size: 1.7rem;
    }

    &__items {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        border-right: 2px solid $color-gallery;
      }

      &-1,
      &-2,
      &-3 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 1.6rem;
        padding-bottom: 1.6rem;
        word-break: break-all;
      }

      &-2 {
        .extra__info {
          width: 80%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          position: relative;
          & li span {
            // max-width: 100%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @media (max-width: $width-1000p) {
            width: 90%;
          }

          li {
            display: flex;
            align-items: center;
            gap: 0.8rem;
          }
        }
      }

      &-3 {
        border-right: 2px solid transparent !important;
      }

      a {
        color: $color-light-white;
      }

      .social__links {
        display: flex;
        gap: 1.8rem;
        justify-content: center;

        a {
          font-size: 2rem;
        }
      }

      &-link {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        a {
          font-size: 1.4rem;
        }
      }
    }
  }
}

// responsive
@media (max-width: 1300px) {
}

@media (max-width: 450px) {
  footer {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .footer__items-1,
  .footer__items-2,
  .footer__items-3,
  .footer__items-4,
  .footer__items-5 {
    grid-row: auto !important;
    grid-column: auto !important;
  }
}

.footer {
  position: relative;
  padding-bottom: 3.4rem;
}

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 2px solid $color-gallery;
  text-align: center;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;

  background: $color-primary;

  a {
    color: $color-light-white;
  }
  @media (max-width: $width-1000p) {
    @media (max-width: $width-mobile-sm) {
      font-size: 1.4rem;
      position: relative;
      border-top: transparent;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -10%;
        width: 150%;
        height: 2px;
        background: $color-gallery;
      }
    }
  }
}
