.healthpage {
  background: $color-healthpage;

  .line {
    color: $color-light-white;
  }

  footer {
    background-color: $color-healthpage;
  }

  .footer .footer__items:not(:last-child) {
    border-right: 2px solid $color-healthpage;
  }

  .copyright {
    border-top-color: $color-healthpage;
  }

  .health {
    &__container {
      flex: 1;

      & .terms__heading {
        font-size: 2.8rem;
        font-weight: bold;
        display: block;
        text-align: center;
      }

      & .line::after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        background: #ffe500;
        width: 10%;
        height: 5px;
      }

      & .terms__list {
        color: $color-light-white;
        line-height: 2.4;
      }

      & .terms__contact {
        color: $color-light-white;
      }
    }

    &__package-info {
      display: flex;
      gap: 1.6rem;

      .service__cards {
        // grid-auto-flow: row;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        padding-top: 0;
        order: 2;

        .service__item-text {
          color: $color-light-white;
        }
      }

      & .service__item {
        justify-content: flex-start;
      }
    }

    &__package-cards {
      flex: 1;
      column-gap: 4rem;
    }

    &__card {
      max-width: 28rem;
    }
  }
}

.test__data {
  color: $color-light-white;
  padding-top: 2.4rem;
  text-align: justify;
}

.tabbed__page-cards {
  grid-template-columns: repeat(2, 1fr) !important;

  .health__card {
    max-width: 90%;

    // updated
    @media (max-width: 550px) {
      max-width: 100%;
    }

    &-description {
      top: -8%;
    }
  }

  @media (max-width: 1026px) {
    // grid-template-columns: repeat(1, 1fr) !important;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}

.health__package-info {
  @media (max-width: 1000px) {
    flex-direction: column;

    .health__container {
      order: 5;
    }
  }
}

.healthpage .health__package-info .service__cards {
  @media (max-width: 1000px) {
    display: grid;
  }
}

.mobile__actions {
  display: none;
  position: absolute;
  top: 0;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #ffe500;
  }

  @media (max-width: 1000px) {
    display: block;
  }
}

.key__points {
  color: $color-light-white;
  padding-top: 2rem;

  h3 {
    color: $color-light-white;
    text-decoration: underline;
    font-size: 2.4rem;
  }

  .suggestion__list {
    padding-left: 3rem;
    line-height: 1.7;
  }

  .outline {
    text-align: center;
    padding-top: 2rem;
    p {
      background: $color-primary;
      // max-width: 40rem;
      max-width: 70rem;
      display: inline-block;
      padding: 1.6rem 2.4rem;
      border-radius: 10px;
      font-size: 2.2rem;
      color: #ffe500;
      font-weight: bold;

      @media (max-width: 600px) {
        font-size: 1.8rem;

        @media (max-width: 500px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}
