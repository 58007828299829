.contact-us {
  display: flex;
  margin: 0 5%;
  box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
  margin-bottom: 3rem;
  // position: absolute;
  // top: 80%;
}

.contact__header {
  min-height: 60vh;
  background: url("../../assets/images/Contact\ Background.png");
  background-position: center;
  background-size: cover;
}

/* sanjit updates */
.contact-us {
  margin-top: -8rem;
}

.contact-us > * {
  width: 100%;
}

.contact__us-form {
  padding: 0 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

/*  */
.form__group {
  width: 100%;
}

.form__group input,
.form__group textarea {
  width: 100%;
  outline: none;
}

.group__elements {
  display: flex;
  gap: 2rem;
}

.group__elements > * {
  width: 100%;
}

.contact-us .contact-us-left {
  /* overflow: hidden; */
  padding: 4rem 3.5rem;
  border-radius: 20px 0 0 20px;
  background-color: #2b4791;
  color: #fff;
}
.contact-us .contact-us-left .contact-us-title {
  font-size: 2rem;
  font-weight: 600;
}
.contact-us .contact-us-left .ridhisidhi-dot-com {
  padding-top: 3rem;
}

.contact-us .contact-us-left .ridhisidhi-dot-com .ridhisidhi-dot-com-title {
  font-size: 2rem;
}

.contact-us .contact-us-left .info {
  padding-top: 1rem;
}
.contact-us .contact-us-left .contact-us-left-address {
  padding-top: 2rem;
}
.contact-us .contact-us-left .contact-us-left-address .address-title {
  font-size: 2rem;
}
.address-para,
.ridhisidhi-dot-com-para {
  padding-top: 0.5rem;
  font-size: 1.4rem;
}

.contact-us .contact-us-left .phone {
  padding-top: 2rem;
}
.contact-us .contact-us-left .phone .phone-title {
  font-size: 2rem;
}
/* .contact-us .contact-us-left .phone .phone-no {
      padding-top: 0.5rem;
  } */
.contact-us .contact-us-left .phone .phone-details {
  display: flex;
  padding-top: 1rem;
}
.contact-us .contact-us-left .phone .phone-details .phone-icon {
  font-size: 2.5rem;
}
.contact-us .contact-us-left .phone .phone-details .phone-content {
  padding-left: 1rem;
}

.contact-us .contact-us-left .any-ques {
  padding-top: 2rem;
  font-weight: bold;
}
.contact-us .contact-us-left .fill-form {
  padding-top: 1rem;
}

.contact-us .contact-us-right {
  background-color: #fff;
  border-radius: 0 20px 20px 0;
  padding-bottom: 3.4rem;
}
.contact-us .contact-us-right h2 {
  padding-top: 1.6rem;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  color: $color-primary;
}

.contact-us .contact-us-right form {
  margin-top: 2rem;
}

.contact-us .contact-us-right form input,
textarea,
button {
  padding: 0.8rem;
  /* margin-left: 5%;
    margin-top: 3rem; */
  outline: none;
  border: 1px solid #000;
}
.contact-us .contact-us-right form button {
  background-color: $color-primary;
  border-style: none;
  width: 10rem;
  color: #fff;
  border-radius: 15px;
  font-weight: bold;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1025px) {
  .contact-us {
    flex-direction: column;
    top: 100%;
  }

  .contact-us .contact-us-left {
    border-radius: 20px 20px 0px 0;
  }

  .contact .contact-us-right {
    border-radius: 0 0 20px 20px;
  }

  /* .contact-background .chat-with-ridhisidhi {
              width:80%;
              top: 5%;
          }
          .contact-background .chat-with-ridhisidhi .chat-head, .chat-para, .login-messenger {
              font-size: 80%;
          }
  
          .break {
              display: none;
          } */
}

@media screen and (max-width: 600px) {
  .chat-with-ridhisidhi {
    display: none;
  }
  .contact-us {
    box-shadow: none;
  }

  .contact-us .contact-us-left {
    // background-color: #fff;
    // color: #111;
  }

  // .contact-us .contact-us-left .contact-us-title {
  //   color: #585cd;
  //   /* text-align: center; */
  // }
  .contact-us .contact-us-left .ridhisidhi-dot-com .ridhisidhi-dot-com-title,
  .contact-us .contact-us-left .contact-us-left-address .address-title,
  .contact-us .contact-us-left .phone .phone-title {
    font-size: 3rem;
  }
  #svg-messenger {
    right: 5%;
    top: 40%;
  }

  .ridhisidhi-dot-com-para,
  .address-para,
  .fill-form,
  .phone-no {
    font-size: 1.4rem;
  }

  .ridhisidhi-dot-com-title,
  .address-title,
  .phone-title {
    font-size: 2.4rem;
  }

  .contact-us {
    margin: 0;
  }

  .contact-us .contact-us-left {
    border-radius: 0;
  }
}

@media (max-width: 560px) {
  .group__elements {
    flex-direction: column;
  }
}

.contact__page .footer {
  background-color: $color-light-white;
}

.contact__page {
  footer .footer__items:not(:last-child) {
    border-right: 2px solid $color-light-white;
  }
}
