.servicepage .footer {
  background-color: $color-service;
}

.lab {
  &__services {
    &-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2.4rem;
      column-gap: 1.6rem;
    }
  }
}

.home__collection {
  background: $color-service;
}


.terms {
  &__heading {
    // font-size: 2.5;
    text-align: left;
    display: inline-block;
    color: $color-term;
  }

  & .line::after {
    // underline
    transform: translateX(0);
    left: 0;
    height: 4px;
    width: 30%;
    bottom: -0.6rem;
  }

  &__list {
    padding-top: 0.8rem;
    padding-left: 1.6rem;
    line-height: 1.8;
  }
}

.servicepage {
  .footer .footer__items:not(:last-child) {
    border-right: 2px solid $color-service;
  }

  .copyright {
    border-top-color: $color-service;
  }
}
