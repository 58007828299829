@import "./base/variable.scss";

// @import "./icon.scss";

@import "./base/base.scss";

@import "./base/typography.scss";

@import "./components/Accordian.scss";

@import "./layout/header.scss";

@import "./layout/footer.scss";

@import "./components/button.scss";

@import "./components/form.scss";

@import "./Pages/Homepage/Homepage.scss";

@import "./Pages/Servicepage.scss";

@import "./Pages/HealthPackage.scss";

@import "./Pages/Contact.scss";

@import "./components/Card.scss";

@import "./components/List.scss";

@import "./abstract/table.scss";

@import "./components/Tab.scss";

@import "./components/Search.scss";

@import "./layout/InputSelect.scss";

@import "./abstract/responsive.scss";

// @import "./layout/modalTable.scss";

@import "./abstract/404pageNotFound.scss";

@import "./abstract/slider.scss";

@import "./components/Navigation.scss";

@import "./components/LinkCard.scss";

@import "./Pages/Teampage.scss";

@import "./Pages/Aboutpage.scss";

@import "./Pages/Document.scss";

// @import "./Pages/Report.scss";
// #root {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// }
// footer {
//   margin-top: auto;
// }
h1 {
  margin: 0;
}
ul {
  list-style: none;
}

.heading-h {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  color: $color-primary;
  font-weight: bold;
}

// .err__form {
//   color: #fff;
//   position: fixed !important;
//   height: 35rem;
//   right: 0;
//   bottom: 0;
//   background-color: #c8102e;
//   z-index: 5000000000;
//   padding: 5px 0 10px 0;
//   margin: 0 5px 5px 0;
//   width: 300px;
//   position: relative;
//   overflow: hidden;
//   transition: max-height 2s linear;
// }
// modal
.modal-ridhisidhi {
  position: fixed;
  background: $color-light-white;
  padding: 2rem 2.4rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  justify-content: center;
  align-items: center;
  &__success {
    width: 45%;
    margin: 0 auto;

    svg {
      width: 100%;
    }

    &__text-heading {
      font-size: 2rem;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    text-align: center;

    &-heading {
      color: $color-black-shade;
      font-size: 2rem;
    }

    &-description {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }
  }
}
.btn-modal {
  padding: 0.5rem 2rem;
  align-self: center;
  border-radius: 20px;
  border: 2px solid $color-primary;
  background: $color-light-white;
  cursor: pointer;
  outline: none;
  color: $color-primary;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.3s;
  &:hover,
  &:active,
  &:focus {
    transform: scale(1.1);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.btn-modal-sm {
  padding: 0.25rem 1rem;
  font-size: 1rem;
  align-self: center;
  border-radius: 20px;
  border: 2px solid $color-primary;
  background: $color-light-white;
  cursor: pointer;
  outline: none;
  color: $color-primary;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.overlay-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  transform: scale(0);
  z-index: 100;
  transition: all 0.1s;
}

.overlay_active {
  transform: scale(1);
}

.show {
  z-index: 500;
  transition: all 0.3s;
  transform: translate(-50%, -50%) scale(1);
}

.links__page {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  a {
    display: flex;
    gap: 1.6rem;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loader-sm {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.links__page-ridhisidhi {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  row-gap: 1.6rem;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.seperate__head {
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;

  span {
    font-size: 2rem;
  }
}

.constant__margin-top {
  margin-top: 2.5rem;
}

.constant__margin-bottom {
  margin-bottom: 2.5rem;
}

.constant__padding-top {
  padding-top: 2.4rem;
}

// .constant__m-top

// gallery page
.gallery__page footer {
  background-color: $color-light-white;
}

.gallery__page {
  .footer .footer__items:not(:last-child) {
    border-right: 2px solid $color-light-white;
  }

  .copyright {
    border-top-color: $color-light-white;
  }
}

.constant__width-detail {
  max-width: 75rem;
  width: 80%;
  margin: 0 auto;
}

.appointment__form {
  // min-height: 100vh;
  position: static;
  height: auto;
}

.contact__us-form {
  & .form__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

// temporarily hide the text
.hide-text {
  opacity: 0;
}

// new updates
.arrange__cards {
  grid-template-columns: repeat(4, 1fr) !important;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr) !important;

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @media (max-width: 565px) {
      grid-template-columns: repeat(1, minmax(15rem, 30rem)) !important;
    }
  }
}

// another slider
.another__slider {
  padding-bottom: 2.4rem;
  &-image {
    max-width: 40rem;
    margin: 0 auto;

    img {
      width: 100%;
    }

    @media (max-width: 568px) {
      max-width: 30rem;

      // img {
      //   margin: 0 auto;
      //   width: 80% !important;
      // }
    }
  }
}

.wrap {
  text-align: end;

  .view-all {
    & {
      font-size: 15px;
      color: $color-primary;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      // left: 100%;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        width: 10%;
        height: 2.5px;
        bottom: -2px;
        left: 0;
        background: $color-primary;
        transition: all 0.3s;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}

.desktop__price {
  display: table;
  @media (max-width: 768px) {
    display: none;
  }
}

.mobile__pricing {
  display: none;

  .head {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // border: 1px solid #fff;
    // row-gap: 1.6rem;

    p {
      padding: 0.8rem;
      border-bottom: 1px solid #fff;
      border: 1px solid #fff;
      // &:nth-child(odd) {
      // }
    }
  }
}

.different__pricing {
  p {
    span:first-child {
      color: #000;
      font-size: 1.6rem;
      font-weight: bold;
      @media (max-width: 768px) {
        &::before {
          display: none;
        }
      }
    }
  }
}

.male_female-discount {
  p:last-child {
    position: relative;
    left: -3rem;
  }
  @media (max-width: 768px) {
    display: none !important;
  }
}

.arrange__cards {
  .male_female-discount {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    .cardTypeList__textBox {
      padding-bottom: 4.4rem;
    }
  }
}

.cardTypeList__textBox {
  margin-bottom: 0;
}
.discounted__section {
  display: flex;
  flex-direction: column;

  p {
    display: flex;

    @media (max-width: 768px) {
      display: block;
    }
    span {
      margin-left: 0.4rem;
    }
  }
}

.lab_services-items {
  display: flex;
}

.gallery__card-section {
  .lab__services-cards {
    display: grid !important;
  }
}

.lab__card-list {
  display: grid;
  column-gap: 2.4rem;
  row-gap: 2.4rem;
  justify-content: center;
  grid-template-columns: repeat(1, minmax(25rem, 35rem));

  @media (min-width: 580px) {
    grid-template-columns: repeat(2, minmax(25rem, 35rem));
    justify-content: space-between;
  }
  @media (min-width: 900px) {
    // grid-template-columns: repeat(2, minmax(25rem, 35rem));
    grid-template-columns: repeat(3, minmax(25rem, 35rem));
    @media (min-width: 1150px) {
    }
  }
  & > * {
    display: none;
    background: #f49421;
    padding: 1.6rem 2.4rem;
    font-size: 1.8rem;
    color: $color-light-white;
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
      font-size: 1.6rem;
    }
    // align-self: center;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(8),
    &:nth-child(10),
    &:nth-child(9) {
      display: flex;
    }
  }

  // @for $i from 1 through length(9) {
  //   .section-#{$i} {
  //     background-color: nth($backgrounds, $i);
  //   }
  // }
}

.detail-service-page {
  .lab__services-cards {
    // display:flex;
    .lab__card-list {
      & > * {
        display: flex !important;
      }
    }
  }
  .lab_servicesCard-filter {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    font-weight: bold;

    @media (max-width: 700px) {
      display: block;
      text-align: center;
    }
    .lab_servicesCard-filterA {
      @media (max-width: 700px) {
        width: 20px;
        display: inline-block;
        justify-content: center;
      }
      cursor: pointer;

      &:hover {
        color: #f49421;
        // border: 1px solid #f49421;
      }
    }
    .active {
      color: #f49421;
      background: transparent !important;
      // border: 1px solid #f49421;
    }
  }
}

.lab__services-cards {
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 0rem;
  }
  & > * {
    background: #f49421;
    max-width: 100%;
    width: 100%;
    margin: 1rem;
    padding: 2rem;
    border-radius: 10px;
    display: grid;
    align-self: flex-start;
  }
}
.lab__card {
  .lab__card-description {
    display: flex;
    align-items: center;

    .title {
      margin-left: 1rem;
      cursor: pointer;
      width: 100%;
    }
  }
}

.no__color,
.gallery__container .whole__card {
  background: transparent !important;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.6rem;
  margin-top: 1.6rem;
  .title {
    @media (max-width: 500px) {
      word-break: break-word;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.6rem;

    div {
      margin: 0;
    }
  }

  & div:first-child {
    & div:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }
}

.lab__card-new {
  background: #f49421;
  max-width: 100%;
  width: 100%;
  margin: 1rem;
  padding: 2rem;
  border-radius: 10px;
  display: grid;
  align-self: flex-start;

  .lab__card-description {
    display: flex;
    align-items: center;
    cursor: pointer;

    .plus {
      margin-right: 0.8rem;
    }

    h1 {
      flex: 1;
    }
  }
}
