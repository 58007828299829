.tabs {
  &__child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding-top: 2.4rem;

    @media (max-width: $width-900p) {
      align-items: flex-start;

      @media (max-width: $width-mobile-xl) {
        display: block;
      }
    }
  }

  &__image {
    width: 20rem;
    img {
      width: 100%;
      // height: 100%;
      display: block;
    }

    @media (max-width: $width-900p) {
      width: 15rem;

      @media (max-width: $width-mobile-xl) {
        width: 10rem;
        float: left;
        margin-right: 0.8rem;
      }
    }
  }

  &__content {
    flex: 1;
    font-family: inherit;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;

    h3 {
      font-weight: 700;
      font-family: inherit;
      font-size: 2.4rem;
      margin-bottom: 1rem;
      color: $color-purple;
    }

    p {
      font-size: 1.4rem;
      text-align: left;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 1rem 0 3rem;
    justify-content: center;
    max-width: 92rem;
    margin: 0 auto;

    // might be needed
    overflow: auto;
    white-space: nowrap;
    justify-content: flex-start;

    @media (max-width: $width-1000plus) {
      overflow: auto;
      white-space: nowrap;
      justify-content: flex-start;
    }
  }

  &__button {
    background: rgba(0, 0, 0, 0);
    padding: 0.4rem;
    color: $color-light-white;
    background: $color-primary;
    padding: 1.4rem 1.6rem;
    box-shadow: none;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    display: block;
    position: relative;
    font-family: inherit;
    border-radius: 15px;
    min-width: 15rem;
    letter-spacing: 1px;

    @media (max-width: $width-1000plus) {
      width: max-content;
    }
  }
}

.tabs__button.active {
  background: $color-orange;
  color: $color-primary;
  transition: visibility 0s, opacity 0.4s ease-in-out;
  // border-radius: 15px;
}

.tabs__item:not(:last-child) {
  margin-right: 20px;
}
