.link__card {
  display: flex;
  flex-direction: column;
}

.link {
  &__card {
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    filter: url(#round);
    &-image {
      clip-path: polygon(0 0, 100% 0, 100% 80%, 17% 80%, 0 98%);
      img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
  }
}

.whole__card {
  max-width: 35rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
  &:hover {
    .link__card {
      transform: scale(0.8);
    }
  }

  &:hover .text__description {
    transform: scale(0.8);
    bottom: 18%;
    left: 0;
  }
}

.text__description {
  position: absolute;
  bottom: 10%;
  left: 1rem;
  width: 100%;
  font-weight: bold;
  color: $color-primary;
  text-decoration: underline;
  font-size: 2rem;
  transition: all 0.3s;
}
