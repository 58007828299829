// responsive
@media (max-width: 1050px) {
  .second__section {
    .chart {
      &__display {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 680px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &__container-1,
      &__container-2,
      &__container-3 {
        max-width: none;

        @media (max-width: 680px) {
          max-width: 95%;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .slick-prev {
    left: -4px !important;
    z-index: 5000;
  }
  .slick-next {
    right: -4px !important;
  }
}

@media (max-width: 700px) {
  .multiple__slider .card-component {
    max-width: 35rem;
    max-height: 28rem !important;
  }
}

@media (max-width: 450px) {
  .multiple__slider .card-component {
    max-width: 30rem;
    max-height: 28rem !important;
  }
}
