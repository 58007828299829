.slider {
  &__section {
    // min-height: 80vh;
    position: relative;

    h2 {
      text-align: center;
    }
  }
  &__container {
    // max-width: 54rem;
    width: 90%;
    position: relative;
    margin: 0 auto;
    margin-top: 3.4rem;

    @media (max-width: $width-mobile-lg) {
      width: 100%;
    }
  }
  &__items {
    position: relative;
    width: 100%;
    // color: #96c2bb;
    // margin: 0 auto;
    // background: rgba(255, 255, 255, 0.25);
    // // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    // backdrop-filter: blur(11.5px);
    // -webkit-backdrop-filter: blur(11.5px);
    // border-radius: 10px;
    // border: 1px solid rgba(255, 255, 255, 0.18);

    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 1.6rem 2.4rem;

    p {
      text-align: center;
    }

    .quote {
      width: 10rem;
      position: absolute;
      svg {
        width: 100%;
      }

      @media (max-width: $width-mobile-lg) {
        width: 6rem;
      }
    }

    .bottom__block-quote {
      bottom: 20%;
      right: 0;
    }

    .top__block-quote {
      // align-self: flex-end;
      top: 20%;
    }
  }

  // slider profile
  &__profile {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    // color: $color-purplish-blue;
    font-weight: 400;
    justify-content: center;
    position: relative;

    .profile__name {
      font-weight: 700;
    }

    .info {
      text-align: center;
    }

    @media (max-width: $width-mobile-lg) {
      margin-top: 0rem;
    }

    &-img {
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 1;
    }

    span {
      font-size: 1.4rem;
    }
  }
}

.slide {
  &-prev,
  &-next {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    position: absolute;
    top: 98%;
    left: 50%;
    transform: translateX(-50%);

    &::before {
      display: none;
    }
  }

  &-next {
    margin-left: 2.55rem;
  }
  &-prev {
    margin-left: -2.55rem;
  }
}

.testimonial__divider {
  max-width: 120rem;
  margin: 0 auto;
  background: $color-light-white;
  margin-top: -12rem;
  border-radius: 15px;
  padding: 2.4rem 1.6rem;

  @media (max-width: $width-1150) {
    margin-top: 0rem;
  }
}

//
.slider {
  &ImageContainer {
    // width: 100%;
    // height: 76vh;
    max-height: 76vh;

    position: relative;

    @media (min-width: 768px) {
      height: 74vh;
      max-height: none;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    img {
      // height: 100%;
      width: 100%;
      display: block;
      // transform: translateY(-19%);
      // object-fit: cover;
    }

    &::after {
      content: "";
      background: hsla(0, 0%, 0%, 0.402);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.ridhisidhi__slider {
  width: 100%;

  &-right {
    right: 27px !important;

    svg {
      background: transparent !important;
      color: #565656 !important;
      color: $color-light-white !important;
      transition: all 0.2s;

      &:hover {
        transform: scale(2);
      }
    }
  }

  &-left {
    left: 27px !important;
    z-index: 5;

    svg {
      background: transparent !important;
      // color: #565656 !important;
      color: $color-light-white !important;
      transition: all 0.2s;

      &:hover {
        transform: scale(2);
      }
    }
  }
  @media (max-width: 1150px) {
    width: 100%;
  }
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.slick {
  &-next svg,
  &-prev svg {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: $color-primary;
    color: #fff;
  }
}

@media (max-width: 800px) {
  .ridhisidhi__slider-right {
    right: 27px !important;
  }
  .ridhisidhi__slider-left {
    left: 27px !important;
    z-index: 5 !important;
  }
}

.custom__slider {
  width: 100%;
  background-color: transparent;
  .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 0.8rem 1.2rem;
    transition: all 0.3s ease;
    height: auto;
    text-align: center;
    margin: 2rem 1rem;
    // margin-top: 0;
    &.slick-current,
    &.slick-center {
      transform: scale(1.2);
      // padding: 3rem 1.5rem;
      //margin: 50px 0px;
    }
  }
}
