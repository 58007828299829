@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

$color-light-white: #fff;
$color-primary: #1b1f4c;
$color-section-background-1: #fff3e4;
$color-blue: #2f327d;
$color-purple: #35c6f3;
$color-dark: #000;
$color-black: #000;
$color-black-shade: #464646;
$color-btn-primary: #ff9201;
$color-orange: #ffe500;
$color-orange-dark: #f39422;
$default-font: 1.6rem;
$color-green: #8ad7bc;
$color-purplish: #34c6f5;
$color-grey: #8ad7bc;
$color-footer-text: #b2b3cf;
$color-gray-dark: #4e4e4e;
$color-gallery: #ddb9b9;
$color-black-shade: #2f2f2f;
$color-table-heading: #b8b8b8;
$color-hover-effect: #b9a810;
$color-price: #f49421;
$color-term: #ed185b;
$color-service: #bad2f1;
$color-card-background: #b0b5f1;
$color-healthpage: #34386e;
$color-about: #3154a3;
// font family
$global-font: poppins;
$secondary-font: Roboto;
$default-font: 1.6rem;

$width-1200: 1200px;
$width-1150: 1150px;
$width-1000p: 1030px;
$width-1000plus: 1050px;
$width-1000: 1000px;
$width-900p: 925px;
$width-tablet: 768px;
$width-mobile-xl: 660px;
// $width-mobile-xsl: 620px;
$width-mobile-lg: 600px;

$width-mobile-580plus: 590px;

$width-mobile-sm: 480px;

.primary__color {
  color: $color-primary !important;
}
