.card {
  &__container {
    //

    display: flex;
    max-width: 130rem;
    justify-content: space-between;
    gap: 2.4rem;
    & > * {
      width: 100%;
    }
  }

  &-component {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    gap: 1.6rem;
    align-items: center;
    max-width: 25rem;
    min-height: 20rem;
    padding: 2.5rem 1.8rem;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 2px solid hsla(0, 0%, 31%, 0.2);

    // updated chart

    .card__image-container {
      width: 20rem;
      flex: 1;
      // max-height: 10rem;

      .chart__header {
        display: none;
      }

      .App {
        height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }

      .chart__img {
        height: 100% !important;
      }
    }

    .img__description {
      font-family: poppins;
      font-weight: 500;
      font-size: 1.6rem;
      color: #5e5e5e;
    }
  }
}

.animate {
  &:hover {
    // background: $color-primary;
  }
  transition: all 0.3s;

  & svg {
    transition: all 0.3s;
  }
  & svg path {
    transition: all 0.4s;
  }

  &:hover svg path,
  &:active svg path,
  &:focus svg path {
    fill: $color-light-white;
  }

  &:hover svg,
  &:active svg,
  &:focus svg {
    transform: scale(1.2);
  }

  &:hover .white__stroke {
    stroke: $color-light-white;
    fill: $color-primary;
  }

  .img__description {
    transition: all 0.3s;
  }

  &:hover .img__description,
  &:active .img__description,
  &:focus .img__description {
    // color: $color-light-white;
    transform: scale(1.2);
  }
}

// responsive
@media (max-width: 1130px) {
  .first__section-card {
    .card {
      &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 900px) {
          grid-template-columns: repeat(2, 1fr);

          @media (max-width: 585px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }

      &-component {
        width: 100%;
        max-width: none;

        & .card__image-container {
          width: auto;
        }
      }
    }
  }
}

// health  card
.health {
  &__package-cards {
    // padding-top: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 4rem;
    column-gap: 2rem;
    justify-items: center;
    // images becuase of
    min-height: 40rem;

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 585px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__card {
    // max-width: 30rem;
    max-width: 35rem;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    height: 100%;

    .health__body svg path {
      transition: all 0.3s;
    }

    @media (min-width: 1000px) {
      &:hover {
        // &-info{
        //   dis
        // }
        .health__body svg path {
          fill: $color-price;
        }

        .health__card-action {
          opacity: 1;
          pointer-events: all;
        }

        .health__card-info {
          opacity: 0;
        }

        .health__card-description .title {
          opacity: 1;
        }

        .health__card-description .normal__description {
          opacity: 0;
        }

        .health__card-description {
          background: hsla(0, 0%, 2%, 0.529);
        }
      }
    }

    &-content {
      position: absolute;
      bottom: 10%;
      width: 100%;
      z-index: 8;
    }
    .title {
      flex: 1;
      font-size: 2rem;
      margin-bottom: 0;

      // updated
      text-overflow: ellipsis;
      width: 30px !important;
      overflow: hidden;
      font-size: 2rem;
      white-space: nowrap;
    }

    &-action {
      position: absolute;
      width: 80%;
      bottom: 0%;
      left: 50%;
      transform: translateX(-50%);
      justify-content: space-between;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;

      button {
        background: $color-primary;
        padding: 0.8rem 1.6rem;
        border: none;
        font-size: 1.6rem;
        font-weight: bold;
        color: $color-light-white;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(0px);
        }
      }

      .price-action {
        color: $color-primary;
        font-weight: bold;
        z-index: 5;
        font-size: 2rem;
      }
    }

    &-info,
    &-action {
      display: flex;
      // height: 80%;
      width: 80%;
      margin: 0 auto;
      align-items: center;
      color: $color-light-white;
      z-index: 5;
      transition: all 0.3s;

      .price {
        z-index: 5;
        font-size: 2rem;
        font-weight: bold;
        color: $color-price;
      }
    }

    &-description {
      background: hsla(0, 0%, 2%, 0.329);
      width: 100%;
      position: absolute;
      height: 100%;
      top: 10%;
      z-index: 2;
      color: $color-light-white;
      transition: all 0.3s;

      .normal__description {
        font-size: 1.4rem;
        display: block;
        width: 100%;
        padding-left: 6rem;
        padding-top: 1.6rem;
        // updated commented
        // white-space: nowrap;
        // width: 50px;
        overflow: hidden;
        text-overflow: clip;
        transition: all 0.3s;
      }

      .title {
        position: absolute;
        top: 0;
        padding-left: 8rem;
        opacity: 0;
        transition: all 0.3s;
      }
    }
  }

  &__top {
    width: 100%;
    height: 100%;

    // updated
    height: 33rem;

    & img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      display: block;
    }
  }

  &__body {
    position: absolute;
    width: 100%;
    background-position: center;
    background-size: cover;
    left: 0;
    height: 50%;
    z-index: 5;
    bottom: 0;

    svg {
      width: 100%;
      transform: scale(1.4);
      position: absolute;
      bottom: 0;
      z-index: 5;
    }
  }
}

// upadated new card list

.cardTypeList {
  // display: flex;
  flex-flow: row wrap;
  // width: 60rem;
  width: 100%;
  // margin: 20px auto;
  display: grid;
  row-gap: 2rem;
  column-gap: 2rem;
  list-style: none;
  // grid-auto-flow: column;
  // grid-auto-columns: minmax(320px, 450px);
  grid-template-columns: repeat(3, minmax(15rem, 30rem));

  @media (max-width: 1200px) {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(15rem, 30rem));

    @media (max-width: 1000px) {
      justify-content: flex-start;
      grid-template-columns: repeat(3, minmax(15rem, 30rem));

      @media (max-width: 900px) {
        justify-content: center;
        grid-template-columns: repeat(2, minmax(15rem, 30rem));

        @media (max-width: 600px) {
          grid-template-columns: repeat(1, minmax(15rem, 30rem));
        }
      }
    }
  }

  &__item {
    // width: 300px;
    // margin-bottom: 20px;
    height: 100%;
    cursor: pointer;

    &:not(:nth-child(2n)) {
      // margin-right: 20px;
    }

    &:hover {
      .action-btn {
        // opacity: 0;
        transform: scaleX(1);
      }

      .cardTypeList__image {
        transform: scale(1.5);
      }
    }
  }

  &__link {
    display: block;
    color: #333333;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 4px 10px 0 rgb(40 49 53 / 20%);
    overflow: hidden;
    background: $color-light-white;
    // increasing the height according to the content
    height: 100%;

    // to make the content go to bottom
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      padding-bottom: 2.4rem;
    }
  }

  &__imageBox {
    // controlling the height of the image
    height: 180px;
    overflow: hidden;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s;
    // height: auto;
  }

  &__textBox {
    padding: 15px;
    box-sizing: border-box;
    position: relative;

    // making the content go to bottom
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    flex: 1;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 10px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__text {
    font-size: 2rem;
    line-height: 1.5;
    color: #f49421;
    display: flex;
    font-weight: bold;

    .price {
      flex: 1;
      font-size: 1.8rem;
      font-weight: 600;

      @media (max-width: 400px) {
        font-size: 1.6rem;
      }
    }
    span:first-child {
      display: block;
    }
  }

  .action-btn {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 0px;
    transform: scaleX(0);
    background: $color-primary;
    color: $color-light-white;
    border: none;
    transition: all 0.3s;
    transform-origin: left;

    @media (max-width: 768px) {
      position: absolute;
      font-size: 1.4rem;
      padding: 0.8rem 1.6rem;
      border-radius: 5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      width: auto;
      height: auto;
      transform: scaleX(1);
      top: auto;
      bottom: -20px;
      right: 10px;
      left: auto;
    }

    // height: 5px;
    align-self: flex-end;
    cursor: pointer;
  }
}

// details page pricing

.updated__description {
  color: $color-light-white;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  .product__main {
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .product__image {
      width: 240px;
      height: 180px;
      flex-shrink: 0;
      @media (max-width: 768px) {
        width: 100%;
        height: 35rem;

        @media (max-width: 500px) {
          aspect-ratio: 1 / 1;
          height: auto;
        }

        img {
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }

      img {
        border-radius: 25px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product__info {
      padding-left: 1.6rem;
      padding-top: 2.4rem;

      @media (max-width: 768px) {
        padding-left: 0;
        padding-top: 1.6rem;
      }

      h4 {
        font-size: 2.4rem;
      }
    }
  }

  .test__packages {
    h2 {
      font-size: 2rem;
      margin-bottom: 0.8rem;
    }
    ul {
      color: #f49421;
    }

    @media (min-width: 768px) {
      padding-left: 1.6rem;
    }
  }

  .pricing {
    border-collapse: collapse;
    width: 100%;
    color: $color-light-white;

    @media (min-width: 768px) {
      margin-left: 1.6rem;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      // background-color: #04aa6d;
      color: white;
    }

    td,
    th {
      border: 1px solid $color-light-white;
      padding: 8px;
      // width: 50%;
    }
  }
}

// creating bullets
.test {
  font-weight: bold;
  p {
    padding-left: 1.6rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background: #f49421;
      width: 6px;
      height: 6px;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }
}

.different__pricing {
  display: flex;
  justify-content: space-between;
  p {
    color: #000;

    span {
      color: #f49421;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    p {
      display: flex;
      white-space: nowrap;

      span {
        padding-left: 0.8rem;
        position: relative;
        &::before {
          content: ":";
          position: absolute;
          left: 0rem;
          color: #000;
        }
      }
    }
  }
}
