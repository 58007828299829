.header {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;

  border-bottom: 2px solid #f59321;
  box-shadow: 0 2px 4px 2px #f59321;
  margin-bottom: 1.6rem;

  @media (max-width: $width-mobile-xl) {
    flex-direction: column;
  }
}
