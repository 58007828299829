.navbar {
  // background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  // height: 80px;
  height: 2.4rem;
  @media (min-width: 960px) {
    margin-top: 1.6rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;

  @media (max-width: 961px) {
    height: auto;
  }
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  height: 100%;
  max-width: 14rem;
  transform: scale(1.2);
  position: relative;
  left: 4rem;

  @media (max-width: 961px) {
    top: 2.5rem;

    @media (max-width: $width-mobile-xl) {
      z-index: -1;
    }

    @media (max-width: $width-mobile-sm) {
      left: auto;
    }
  }
}

.navbar-logo img {
  height: 100%;
  width: 100%;
  display: block;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  // display: grid;
  // grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  // width: 70vw;
  // justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  // height: 8rem;
}

.nav-links {
  color: $color-primary;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
}

.nav-links:hover {
  background-color: $color-primary;
  color: $color-light-white;
  border-radius: 0.4rem;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;

  @media (max-width: 961px) {
    top: 11%;
    transform: translate(-50%, -60%);
  }
}

.nav-menu.active .nav-item a {
  font-size: 2rem;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: $color-primary;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    // making the navigation reach top
    top: 0;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }

  .active {
    background-color: $color-primary;
    display: block;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: $color-light-white;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    // position: absolute;
    // top: 0;
    // left: 0;
    // transform: translate(25%, 50%);
    align-self: flex-start;
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 5;
    .fas.fa-times {
      color: $color-light-white;
    }

    @media (max-width: $width-mobile-xl) {
      top: 10%;
    }
  }

  .menu-icon .fa-times,
  .fa-bars {
    color: #000;
    font-size: 2rem;
    @media (max-width: $width-mobile-xl) {
      font-size: 3rem;
    }
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    // display: none;
  }
}

.nav-item {
  position: relative;
}

.dropdown-menu {
  background: $color-primary;
  width: 20rem;
  position: absolute;
  top: 3.2rem;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  // background: #1888ff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

// temporary fix
body iframe {
  display: none !important;
}

.hovered-multi {
  background: $color-primary;
  color: $color-light-white;
}
