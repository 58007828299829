.mainbox {
  background-color: #fff;
  margin: auto;
  height: calc(100vh - 72.65px);
  width: 100vw;
  position: relative;
}

.err {
  color: $color-primary;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  &__box {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
  }
}

.far {
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: $color-primary;
}

.err2 {
  color: $color-primary;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
}

.msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  position: absolute;
  left: 16%;
  top: 45%;
  width: 75%;
  color: $color-primary;
}

// a {
//   text-decoration: none;
//   color: white;
// }

// a:hover {
//   text-decoration: underline;
// }
