.list {
  &__container {
    display: flex;
    // flex-direction: column;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 600px) {
    }
  }

  &__pdf-image {
    width: 50%;
    max-height: 15rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__file-actions {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    max-width: 20rem;
    font-size: 1.4rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0.8rem 1.6rem;
    gap: 1rem;
    align-items: center;
    border-radius: 15px;
    // margin-bottom: 2.4rem;
    // recent changes
    flex-direction: column;
    div {
      font-family: poppins;
      color: $color-dark;
    }

    .see__icon {
      width: 2rem;
      //   margin-top: 0.8rem;

      svg {
        width: 100%;
      }
    }

    a {
      display: block;
      margin-top: 0.8rem;

      button {
        outline: none;
        border: none;
        width: 2rem;
        background: transparent;

        svg {
          width: 100%;
        }
      }
    }

    .fileName {
      flex: 1;
      color: $color-gray-dark;
      font-weight: 500;
      //   if needed
      white-space: nowrap;
      // width: 50px;
      width: 14rem;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.listView {
  // display: ;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  & .list__item {
    max-width: none;
    flex-direction: row;
    flex: 1;
    border-radius: 0;
  }

  & .list__pdf-image {
    display: none;
  }

  .list__file-actions {
    width: auto;
  }
  .list__item .see__icon {
    margin-right: 1.4rem;
  }

  .list__item .fileName {
    text-align: left;
  }
}
