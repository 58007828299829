.heading__primary {
  font-size: 2.8rem;
  font-weight: bold;
}

.normal__text {
  font-size: 1.6rem;
}

.heading__secondary {
  font-size: 2rem;
}
