.options__container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;
}
.default-style {
  .dropdown__container {
    display: flex;
    gap: 2rem;

    .ui {
      min-width: 25rem;
    }

    .form__group {
      height: 100%;
      label {
        min-width: 12rem;
        display: flex;
        justify-content: space-between;
      }

      .ui.selection.dropdown {
        height: 100%;
        line-height: 0.5em;
        padding: 0.9em 2.1em 0.9em 1em;

        .icon {
          color: $color-primary;
        }
      }
    }

    .options {
      &__creator {
        line-height: 0.5em;
        padding: 0.9em 2.1em 0.9em 1em;
        border: none;
        word-wrap: break-word;
        white-space: normal;
        outline: 0;
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
        // min-width: 14em;
        // min-height: 2.71428571em;
        background: #fff;
        display: inline-block;
        color: rgba(0, 0, 0, 0.87);
        -webkit-box-shadow: none;
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0.28571429rem;
        -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
        transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease, width 0.1s ease;
        transition: box-shadow 0.1s ease, width 0.1s ease,
          -webkit-box-shadow 0.1s ease;

        &:focus,
        &:active {
          border-color: #96c8da;
          -webkit-box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
          box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
        }
      }

      &__button {
        float: left;
        min-width: 15rem;
        max-width: 25rem;
        display: block;
        padding: 0.5em 1em;
        border: none;
        color: inherit;
        vertical-align: middle;
        position: relative;
        z-index: 1;
        -webkit-backface-visibility: hidden;
        background: $color-primary;
        color: #fff;
        transition: all 0.3s, color 0.3s;
        position: relative;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        -webkit-transition: background-color 0.3s, color 0.3s;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 2px solid $color-primary;
          z-index: -1;
          border-radius: inherit;
          opacity: 0;
          -webkit-transform: scale3d(0.6, 0.6, 1);
          transform: scale3d(0.6, 0.6, 1);
          -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
          transition: transform 0.3s, opacity 0.3s;
          -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
          transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        }

        &:hover::before,
        &:active::before,
        &:focus::before {
          -webkit-transform: scale3d(1, 1, 1);
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          transform: scale3d(1, 1, 1);
          opacity: 1;
        }

        &:hover,
        &:focus,
        &:active {
          background-color: #fff;
          color: $color-primary;
        }
      }
    }

    &:last-child {
      align-self: flex-end;
    }
  }
}

// search
