.aboutpage {
  .who-we {
    &__img {
      width: 80%;
      margin: 0 auto;
      svg {
        width: 100%;
      }
    }

    &-text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 4rem;

      @media (max-width: $width-1000) {
        gap: 2rem;
      }

      img {
        position: absolute;
        width: 50%;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: $width-1000) {
          // width: 30%;
          width: 20%;
        }
      }

      h3 {
        color: $color-primary;
      }

      P {
        text-align: justify;
        line-height: 1.8;
      }
    }

    .actions__content {
      padding-top: 1.6rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.6rem;
    }

    .caption {
      color: $color-orange-dark;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
    }
  }

  .why__us {
    background: #fedada;
    padding-bottom: 4rem;
    padding-top: 4rem;

    .heading__primary {
      color: #1b1f4c;
      text-align: left;
    }

    .line::after {
      left: 0;
      transform: translateX(0);
    }

    .why__we-list {
      padding-left: 2.4rem;
    }

    .focus__text {
      // padding-left: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h2 {
        color: #ea5253;
        font-size: 4rem;
        font-weight: 900;
        text-align: center;
        text-decoration: underline;

        @media (max-width: 400px) {
          font-size: 3.2rem;
        }
      }
    }

    .youtube__content img {
      width: 56%;

      @media (max-width: 500px) {
        width: 80%;
      }
    }
  }
}

.common__width {
  width: 80%;
}

.technology {
  .who-we-text {
    img {
      width: 20%;
    }
  }
}

.common__setting {
  display: flex;
  gap: 2.4rem;

  & > * {
    width: 100%;
  }

  @media (max-width: $width-1000) {
    flex-direction: column;
  }
}

.chairman {
  &__message {
    background: $color-about;
    color: $color-light-white;

    .line {
      color: $color-light-white !important;
    }
  }

  &__container {
    height: 80vh;
    position: relative;

    @media (max-width: 458px) {
      height: 55vh;
    }
    img {
      width: 60%;
      // height: 10;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 1000px) {
        top: 0;
        width: 80%;
        height: 80%;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
      }

      @media (max-width: $width-mobile-sm) {
        // width: 100%;
        // width: 75%;
      }
    }

    @media (max-width: $width-mobile-xl) {
      // max-height: 50vh;
    }

    .chairman__picture {
      background: #34c6f5;
      filter: blur(53px);
      width: 100%;
      height: 100%;
    }
  }
}

.youtube__content {
  display: grid;

  img {
    width: 80%;
    margin: 0 auto;

    @media (max-width: $width-1000) {
      width: 60%;

      @media (max-width: $width-mobile-lg) {
        width: 80%;
      }
    }
  }
}

.technology {
  @media (max-width: $width-1000) {
    .who-we-text {
      order: 2;
    }
  }
}

// cards
.mission__cards {
  background: $color-service;
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  column-gap: 1.6rem;
  padding-top: 8rem;
  padding-bottom: 8rem;

  @media (max-width: $width-1150) {
    row-gap: 8rem;
    grid-template-columns: repeat(3, 1fr);
    .mission__card {
      margin: 0 auto;
    }

    @media (max-width: $width-900p) {
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: $width-mobile-580plus) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .mission__card {
    max-width: 30rem;
    border-radius: 15px;
    padding: 5rem 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $color-card-background;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-self: flex-start;

    & .image__content {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-light-white;

      margin-top: -10rem;

      img,
      svg {
        width: 60%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .card__heading {
        font-size: 2rem;
        font-weight: bold;
        color: $color-primary;
        text-align: center;
        margin: 1rem;
      }

      p {
        text-align: justify;
      }
    }
  }
}
