.homepage {
  max-width: 150rem;
  margin: 0 auto;
  //
  position: relative;
}

.announcement__notice {
  max-width: 80rem;
  // height: 15vh;
  margin: 0 auto;
  // background-color: red;
  display: flex;
  justify-content: space-between;
  position: relative;
  box-shadow: inset 0 0 0 1px #ed185b;
  overflow: hidden;

  // .hexagone {
  //   width: 20rem;
  //   filter: url(#round);
  //   position: relative;
  // }

  // .hexagone::before {
  //   content: "";
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   background: yellow;
  //   clip-path: polygon(0 0, 100% 0, 49% 50%, 100% 100%, 0 100%);
  // }

  .first__box {
    padding: 0.8rem;
    display: flex;
    gap: 0.8rem;
    width: 20rem;
    position: relative;
    background: #555a8f;
    clip-path: polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%);
    z-index: 5;

    @media (max-width: $width-tablet) {
      flex-shrink: 0;
    }

    .text,
    svg {
      position: relative;
      z-index: 6;
      color: $color-light-white;
    }

    svg {
      width: 25px;
    }
  }

  .second__box {
    // clip-path: polygon(0 50%, 35% 0, 100% 0, 100% 100%, 35% 100%);
    clip-path: polygon(0 50%, 14% 0, 100% 0, 100% 100%, 14% 100%);
    background: #555a8f;
    width: 10rem;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 5;
    .circle {
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translateY(-50%);
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      background: $color-light-white;
      animation: blinkColor 2s infinite linear alternate;
    }
    // position: absolute;
    // height: 100%;
    // left: 20rem;
    // background-color: yellow;
  }

  .notice__text {
    display: flex;
    height: 100%;
    align-self: center;
    color: #ed185b;
    animation-name: rightToLeft;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    flex: 1;

    &:hover {
      animation-name: none;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  @keyframes rightToLeft {
    0% {
      transform: translateX(20rem);
    }
    100% {
      transform: translateX(-45rem);
    }
  }

  @keyframes blinkColor {
    0% {
      background-color: #ffe600;
    }
  }
}

.navgation__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 961px) {
    height: 15rem;

    @media (max-width: $width-mobile-xl) {
      margin-top: 1.6rem;
      width: 100%;
    }
  }
}

.top__navigation {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  gap: 1.6rem;

  @media (max-width: 961px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 1.6rem;

    @media (max-width: $width-mobile-xl) {
      width: 100%;
      padding-right: 0;
    }
  }

  .btn-patient {
    padding: 1rem 2rem;
    background: $color-primary;
    color: $color-light-white;
    transition: all 0.3s;

    &:hover,
    &:active {
      box-shadow: 0 0 0 3px #ffe500;
      border-radius: 5px;
    }

    @media (max-width: $width-mobile-xl) {
      // width: 100%;
    }
  }
}

.search__container {
  min-width: 20rem;
  text-align: end;

  @media (max-width: $width-1150) {
    display: none;
  }
}

.navbar .bottom__mobile {
  position: absolute;
  bottom: 5%;
  width: 100%;
  justify-content: space-around;
  display: none;
}

.contact {
  &-info {
    display: flex;
    // flex-direction: column;
    gap: 0.8rem;

    @media (max-width: $width-mobile-xl) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    font-size: 1.3rem;

    &-text {
      background: $color-purplish;
      display: flex;
      align-items: center;
      padding: 0.8rem 1.6rem;
      text-align: center;

      margin-left: -0.4rem;
      color: $color-light-white;
      height: 3rem;
    }
  }

  &__icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    padding: 2px 4px;
    background: $color-purplish;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid $color-light-white;
    transform: scale(1.2);
    svg {
      width: 80%;
      height: 80%;
    }
  }
}

// hero section
.hero__section {
  //   background-image: linear-gradient(
  //       105deg,
  //       rgba(255, 255, 255, 0.9) 0%,
  //       rgba(255, 255, 255, 0.9) 50%,
  //       transparent 50%
  //     ),
  //     url(../img/nat-10.jpg);
}

// our service
.service {
  &__container {
    // testing
    // margin-top: 2.8rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    // min-height: 35rem;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    transition: all 0.3s;
    justify-content: center;

    cursor: pointer;

    // test
    max-width: 27rem;
    width: 100%;
    &:hover {
      .service__item-icon {
        border-radius: 5px;
        background: $color-hover-effect;
      }
    }

    @media (max-width: $width-900p) {
      justify-content: flex-start;
    }
  }

  &__item-text {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 30px;
      height: 5px;
      background: $color-orange;
    }
  }

  &__item-icon {
    background: $color-orange;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    transition: all 0.3s;

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__cards {
    // display: grid;
    padding-top: 3.4rem;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-items: center;
    display: grid;

    @media (max-width: $width-900p) {
      grid-auto-flow: row;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: row;
      row-gap: 2.4rem;
      margin: 0 auto;
      max-width: 65rem;

      @media (max-width: $width-mobile-xl) {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
      }
    }

    // gap: 1em;
    // grid-template-columns: repeat(auto-fit, minmax(10em, 25em));
  }
}

.service__item-text::after {
  transition: all 0.3s;
}

.service__item:hover .service__item-text::after {
  width: 100%;
}

.line {
  text-align: center;
  position: relative;
}

.line::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: $color-orange;
  width: 10%;
  height: 5px;
}

// welcome
.welcome {
  background: #3257a8;
  color: $color-light-white;
  padding-top: 4rem;

  .line {
    line-height: 1.4;
  }

  p {
    text-align: center;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.6rem;
    min-height: 80vh;
    margin-top: 8rem;

    @media (max-width: $width-1150) {
      min-height: auto;
      padding-bottom: 2.4rem;

      @media (max-width: $width-1000p) {
        grid-template-columns: repeat(2, 1fr);
        max-width: 74rem;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $width-mobile-lg) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-dark;
    padding: 0.8rem 1.6rem;
    border-radius: 15px;
    max-width: 90%;
    height: 30rem;
    margin: 0 auto;

    @media (max-width: 1300px) {
      max-width: 100%;
      @media (max-width: $width-1150) {
        height: auto;

        @media (max-width: $width-1000p) {
          min-height: 35rem;
          max-width: 35rem;

          @media (max-width: $width-mobile-xl) {
            max-width: 30rem;
          }
        }
      }
    }

    //testing
    justify-content: center;
    &:nth-child(odd) {
      background: $color-light-white;
    }
    &:nth-child(even) {
      background: #ec99a8;
      position: relative;
      top: 15rem;

      @media (max-width: $width-1150) {
        position: static;
      }
    }
  }

  &__heading {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  &__image {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    &:nth-child(odd) {
      background: #c4c4c4;
    }

    &:nth-child(even) {
      background: #ffd8aa;
    }

    svg,
    img {
      width: 80%;
      height: 80%;
      transform: scale(2);
      object-fit: contain;
      margin-top: 3rem;
    }
  }
}

// gallery
.gallery {
  // no testimonial then remove margin
  // margin-top: -8rem;

  background: $color-gallery;
  padding-top: 8rem;
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 4rem;

    @media (max-width: 1300px) {
      & > * {
        max-width: 90%;
      }

      @media (max-width: $width-900p) {
        grid-template-columns: repeat(2, 1fr) !important;

        & > * {
          margin: 0 auto;
        }

        @media (max-width: $width-mobile-580plus) {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }
  }
}

.mobile-notice__text {
  display: none;
  @media (max-width: 500px) {
    display: block;
    text-align: center;
  }
}

// changed cardds and design after content
.welcome__cards {
  grid-template-columns: repeat(2, 1fr) !important;
  max-width: 74rem;
  margin-left: auto;
  margin-right: auto;
  min-height: auto !important;
  padding-bottom: 2.4rem !important;

  .welcome__card {
    position: static !important;
    min-height: 35rem !important;
    max-width: 35rem !important;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.first__box {
  @media (max-width: 768px) {
    width: auto !important;
    .text {
      display: none;
    }
  }
}

.text__scroller {
  // height: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.mobile-notice__text {
  display: none;
}
