.heading__primary {
  font-family: poppins;
}
.teampage {
  h3 {
    color: $color-primary;
  }

  .team__text-summary {
    padding-top: 2.4rem;
  }

  p {
    text-align: center;
    font-size: 1.6rem;
    max-width: 50%;

    @media (max-width: 1000px) {
      max-width: 70%;

      @media (max-width: 700px) {
        max-width: 90%;
      }
    }
    margin: 0 auto;
    color: $color-price;
  }

  .animate__element {
    width: 8rem;
    height: 8rem;
    background-color: transparent;
    position: absolute;
    border-width: 10px;
    border-style: solid;

    @media (max-width: 600px) {
      width: 6rem;
      height: 6rem;
      border-width: 8px;

      @media (max-width: 400px) {
        width: 4rem;
        height: 4rem;
        border-width: 6px;
      }
    }

    &-1 {
      /* after image conformation change the top value if necessary*/
      top: -6%;
      left: -2%;
      border-color: #ffbb07;
      border-right-color: transparent;
      border-bottom-color: transparent;
      z-index: 50000;
    }

    &-2 {
      bottom: -6%;
      right: -2%;
      border-color: #000000d0;
      border-top-color: transparent;
      border-left-color: transparent;
      z-index: 500;
    }
  }

  // updated
  .team {
    &__card {
      padding-bottom: 4rem;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.23);
      }
    }

    &__card-profile {
      padding-top: 4rem;
      display: flex;
      align-items: center;

      @media (max-width: 1000px) {
        flex-direction: column;
        padding-top: 2.4rem;
      }

      & > * {
        width: 100%;
      }
    }

    &__card-description {
      height: 100%;
      p {
        font-family: poppins;
        font-size: 1.6rem;
        max-width: 75%;
        // text-align: justify;
        text-align: left;
        color: #0b0b0b;
        display: flex;
        align-items: center;
        height: 100%;

        @media (max-width: 768px) {
          max-width: 95%;
        }
      }
    }

    &__card-img {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      max-height: 30rem;

      @media (max-width: 1000px) {
        max-width: 80%;
        // max-height: none;
        margin-bottom: 4.8rem;

        @media (max-width: 600px) {
          max-width: 100%;
        }
      }
      img {
        display: block;
        // margin: 0 auto;
        width: 100%;
        object-fit: contain;

        @media (max-width: 1030px) {
          width: 100%;
        }
      }
    }
  }

  .team {
    &__card-description {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      position: relative;
      z-index: 20;
      @media (min-width: 1200px) {
        width: 136% !important;
      }
      .team__card-list {
        & li:first-child {
          position: relative;
          font-size: 2rem;
          margin-bottom: 0.8rem;
          font-weight: bold;
          text-decoration: underline;
          text-decoration-color: #ffe500;
        }
      }
    }

    &__image-content {
      position: relative;

      @media (max-width: $width-1000p) {
        display: flex;
        justify-content: center;
        padding-bottom: 1.6rem;
      }
    }
    &__cards {
      display: flex;
      padding-top: 3.2rem;
      justify-content: space-between;
      flex-direction: column;

      @media (max-width: $width-1000p) {
        padding-top: 0;
      }

      .team__card {
        display: flex;
        padding-top: 3.2rem;
        justify-content: space-between;

        @media (max-width: $width-1000p) {
          flex-direction: column;
        }

        &:odd {
          .team__card-description {
            order: 2;
          }
          .team__card-image {
            order: 1;
          }
        }

        & > * {
          width: 100%;
        }
      }

      .shape__svg {
        // position: absolute;
        // width: 90%;
        width: 100%;
        // transform: translateY(-50%);
        transform: scale(1.2);
        position: relative;
        right: 13%;
        pointer-events: none;

        @media (max-width: $width-1200) {
          opacity: 0;

          @media (max-width: $width-1000p) {
            display: none;
          }
        }
      }
    }

    &__card-image {
      position: relative;
      margin-top: -5rem;

      @media (max-width: $width-1000p) {
        margin-top: 0rem;
      }

      .team__content {
        width: 60%;
      }
    }

    &__card-profile {
      font-size: 1.6rem;
      color: $color-light-white;
      line-height: 0.2;
      text-align: center;

      @media (max-width: $width-1200) {
        color: #000;
      }

      h4 {
        font-size: 2rem;
      }

      &-container {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-65%, -50%);

        @media (max-width: $width-1200) {
          transform: translate(-50%, -50%);

          @media (max-width: $width-1000p) {
            position: static;
            transform: translate(0%, 0%);
          }
        }
      }
      &-img {
        max-width: 37rem;

        @media (max-width: $width-1200) {
          width: 37rem;
          max-width: auto;

          @media (max-width: $width-1000p) {
            max-width: 37rem;
            width: auto;

            @media (max-width: $width-mobile-sm) {
              max-width: 26rem;
            }
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.team {
  &__card:nth-child(2n) {
    .team__image-content {
      .shape__svg {
        right: -10%;
      }
    }

    .team__card-profile-container {
      left: 50%;
      transform: translate(-45%, -50%);

      @media (max-width: $width-1200) {
        transform: translate(-50%, -50%);

        @media (max-width: $width-1200) {
          transform: translate(0%, 0%);
        }
      }

      .team__card-profile-img {
        width: 37rem;

        @media (max-width: $width-1200) {
          max-width: 37rem;
          width: auto;

          @media (max-width: $width-mobile-sm) {
            max-width: 26rem;
          }
        }
      }

      .team__card-description {
        text-align: end;
      }
    }
  }
}

.teampage .team__card-description {
  @media (max-width: $width-1000p) {
    order: 3 !important;
  }
}
