.document__page {
  .table__container {
    display: flex;
    flex-direction: column;
    // gap: 2.4rem;
  }

  .table__image {
    position: absolute;
    top: 10%;
    left: 10%;

    img {
      width: 50%;
    }
  }

  .heading__primary {
    color: $color-primary;
  }

  .research__table {
    background: radial-gradient(
      91.32% 159.81% at 87.46% 6.07%,
      #96c2bb 14%,
      #6bb3a7 100%
    );
    min-height: 80vh;

    @media (max-width: $width-mobile-sm) {
      min-height: 43vh;
    }

    table {
      background: rgba(255, 255, 255, 0.6);

      tr {
        text-align: center;
      }
    }
  }

  .table__container-table {
    padding-top: 4rem;
    padding-bottom: 4rem;
    position: relative;
  }

  .document__table {
    width: 100%;
    border-collapse: collapse;
    background-color: transparent;
    position: relative;
    z-index: 10;

    th,
    td {
      border: 1px solid #2f2e2e;
      padding: 0.8rem 1.6rem;
    }

    th {
      color: #f49421;
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 1.6rem;
      button,
      a {
        width: 25px;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        transition: all 0.3s;
        svg {
          width: 100%;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
