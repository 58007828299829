.search__form {
  position: relative;
  display: inline-block;
}

.search__form label {
  position: relative;
  z-index: 2;
  padding-right: 1.2rem;
  top: 5px;
}

.search__form label svg path {
  stroke: #000;
}

.search__form input {
  position: absolute;
  right: 0;
  outline: none;
  border: transparent;
  border-radius: 30px;
  font-size: 1.6rem;
  padding: 0.4rem;
  z-index: 1;
  width: 20px;
  padding-left: 1.6rem;
  padding-right: 3rem;
  opacity: 0;
  transition: all 0.3s ease-in;
  border: 2px solid rgb(99 99 99 / 20%);
}

#search:focus {
  width: 20rem;
  opacity: 1;
  border: 2px solid rgb(99 99 99 / 20%);
}

#search:focus ~ label svg path {
  stroke: $color-primary;
}
