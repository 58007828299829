$color-primary: #1b1f4c;

.table__size {
  width: 100%;
  flex-direction: column;

  // background: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  .health {
    &__body {
      position: static;
    }

    &__card {
      max-width: 75rem;

      // updated
      @media (max-width: 500px) {
        max-width: 100%;
        width: 100%;
      }

      &:hover {
        // &-info{
        //   dis
        // }
        .health__body svg path {
          fill: $color-primary;
        }

        .health__card-action {
          opacity: 0;
          pointer-events: none;
        }

        .health__card-info {
          opacity: 1;
        }

        .health__card-description .title {
          opacity: 1;
        }

        .health__card-description .normal__description {
          opacity: 1;
        }

        .health__card-description {
          // background: hsla(0, 0%, 2%, 0.529);
        }
      }

      .health__card-description {
        top: auto;
        bottom: 0%;
        height: 50%;
        padding: 1.6rem 4rem;

        .title {
          position: static;
          opacity: 1;
          font-size: 2rem;
          padding-left: 0rem;
          width: auto !important;
          word-wrap: break-word;
        }

        .normal__description {
          padding-left: 5rem;
        }
      }
    }
  }

  .table__card {
    position: relative;
    z-index: 5;
    margin-top: -14rem;
    /* margin-left: -25rem; */
    background: $color-primary;
    border-radius: 15px;
    padding-bottom: 4rem;

    @media (max-width: 500px) {
      // max-width: 100%;
      width: 100%;
    }
    .data {
      &__container {
        width: 80%;
        position: relative;
        margin: 0 auto;
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 100%;
          background-color: $color-light-white;
          // updated for adjustment
          @media (max-width: 550px) {
            display: none;
          }
        }
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 1px solid $color-light-white;
      padding: 1.2rem 1.8rem;
      color: $color-light-white;

      // updated for adjustment
      @media (max-width: 550px) {
        flex-direction: column;
      }
      & > * {
        width: 100%;
      }
      font-size: 1.6rem;

      &__heading {
        font-size: 1.6rem;
        margin-bottom: 0;
      }

      &__value {
        padding-left: 2rem;
        height: 100%;
        // border-left: 2px solid $color-light-white;
        position: relative;
        //
      }
    }

    .test {
      &__list {
        padding-top: 2.4rem;
        color: $color-price;
        font-size: 1.8rem;
        font-weight: bold;
        list-style-type: disc;
        padding-left: 1.6rem;

        & li {
          color: $color-price;
          padding-bottom: 0.2rem;
        }
      }
    }
  }
}

.table__size .table__card .data__container {
  top: 30px;
}

.table__size .health__card .health__card-description {
  bottom: 27%;
}

.div__table {
  overflow: auto;
}

.form__data {
  display: flex;

  span:first-child {
    width: 12rem;
  }

  span:last-child {
    text-transform: capitalize;
  }
}

.actions__report {
  display: flex;
  align-items: center;
  gap: 2rem;

  div {
    position: relative;
    cursor: pointer;
    color: #834a00d3;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 15px;
      height: 2px;
      background: #834a00d3;
      bottom: 0;
      transition: all 0.3s;
    }

    &:hover::after {
      width: 100%;
    }
  }
}

.doctor__report {
  border: 2px solid $color-primary;
}

.modal__report {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  padding: 3.4rem 0;
  overflow: auto;
}

.cut {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: #f49421;
    width: 100%;
    height: 3px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.price__divide {
  display: flex;
  flex-direction: column;
}

// updated individual card
.individual__card .health__top {
  height: 44rem;

  @media (max-width: 650px) {
    height: 35rem;
  }
}
